@import "~@fider/assets/styles/variables.scss";

.c-image-upload {
  input[type="file"] {
    display: none;
  }

  .c-form-field-wrapper {
    .c-button {
      font-size: $font-size-big;
      padding: 4px 8px;
    }
  }

  .preview {
    position: relative;
    display: inline-block;
    img {
      padding: 5px;
      min-width: 50px;
      min-height: 50px;
      border: 1px solid $gray-light;
      cursor: pointer;
    }
    .c-button {
      font-weight: 600;
      position: absolute;
      border-color: $red;
      top: 4px;
      right: 0px;
      border-radius: 50%;
      padding: 4px 6px;
    }
  }
}
