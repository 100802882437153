@import "~@fider/assets/styles/variables.scss";

.c-tag {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  color: $gray-darkest;
  font-weight: 600;
  border-radius: 3px;
  border: 0;
  box-shadow: inset 0 -1px 0 rgba(27, 31, 35, 0.12);

  svg.icon {
    font-size: $font-size-micro;
    margin-top: -3px;
    margin-right: 2px;
  }

  &:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.m-circular {
    min-height: 0;
    min-width: 0;
    overflow: hidden;
    border-radius: 50px;

    &.m-mini {
      padding: 5px;
    }

    &.m-tiny {
      padding: 6px;
    }

    &.m-small {
      padding: 7px;
    }

    &.m-normal {
      padding: 7.5px;
    }

    &.m-large {
      padding: 8px;
    }
  }

  &.m-mini {
    font-size: 75%;
    padding: 3px 5px;
  }

  &.m-tiny {
    font-size: 80%;
    padding: 4px 6px;
  }

  &.m-small {
    font-size: 85%;
    padding: 5px 7px;
  }

  &.m-normal {
    font-size: 90%;
    padding: 5px 8px;
  }
}
