@import "~@fider/assets/styles/variables.scss";

@mixin menu-item {
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-decoration: none;
  flex: 0 0 auto;
  user-select: none;
  padding: 15px 10px;
  display: flex;
  align-items: center;
}

#c-header {
  .c-env-info {
    padding: 2px;
    border-radius: 0px;
    font-size: 10px;
    color: $red-darkest;
    border-bottom: 1px solid $red-darkest;
    background-color: rgba($red, 0.05);
  }

  .c-menu {
    margin-top: 0px;
    height: 50px;
    border-radius: 0px;
    border: none;
    display: flex;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    margin-bottom: 10px;

    .container {
      display: flex;
    }

    @include media("sm") {
      height: 40px;
      .container {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .c-menu-item-title {
      @include menu-item;
      color: #484848;
      img {
        margin-right: 10px;
        width: auto;
        height: auto;
        max-height: 36px;
        @include media("sm") {
          max-height: 26px;
          margin-right: 5px;
        }
      }
      h1 {
        font-size: $font-size-big;
        margin: 0 0 0 0;
        font-weight: 600;
        @include media("sm") {
          font-size: $font-size;
          max-width: 240px;
        }
      }
    }

    .c-menu-item-signin {
      @include menu-item;
      cursor: pointer;
      font-size: 90%;
      margin-left: auto !important;
      margin-bottom: 0;

      > span {
        font-weight: 600;
        text-transform: uppercase;
        color: $gray-darker;
      }

      &:hover {
        > span {
          color: $gray-darkest;
        }
      }

      .c-menu-user {
        display: none;
        position: absolute;
        overflow: visible;
        width: auto;
        height: auto;
        top: 100% !important;
        opacity: 1;
        z-index: 11;
        background-color: $white;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
        border: 1px solid rgba(34, 36, 38, 0.15);
        min-width: 160px;
        border-radius: 0px 0px 5px 5px;

        span {
          vertical-align: middle;
        }

        .c-menu-user-heading {
          white-space: nowrap;
          font-size: $font-size-mini;
          padding: 10px;
          display: block;
          color: $gray-darkest;
          text-transform: uppercase;
          font-weight: 600;
          cursor: default;
        }

        .c-menu-user-item {
          font-size: $font-size-small;
          padding: 10px;
          display: block;
          color: $gray-darkest;
          &:hover {
            background-color: $gray-lighter;
          }
        }
        .c-menu-user-divider {
          border-top: 1px solid rgba(34, 36, 38, 0.1);
          display: block;
          cursor: default;
        }
      }

      &:hover {
        .c-menu-user {
          display: block;
          left: auto;
          right: 0;
        }
      }

      .c-unread-dot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-left: -10px;
        margin-top: -25px;
        background-color: $red;
        border: 1px solid $white !important;
      }

      .c-unread-count {
        display: inline-block;
        background-color: $red;
        position: absolute;
        right: 5px;
        padding: 2px 8px;
        color: $white;
        border-radius: 100px;
        font-size: $font-size-mini;
        text-align: center;
      }
    }
  }
}
