@import "~@fider/assets/styles/variables.scss";

.c-button {
  -webkit-appearance: none;
  appearance: none;
  line-height: 1.15;
  display: inline-block;
  cursor: pointer;
  font-size: $font-size;
  border-radius: 3px;
  border: none;
  margin: 0 4px 0 0;
  padding: 8px 16px;
  text-align: left;
  font-weight: 600;

  .icon {
    margin-top: -3px;
  }

  &.m-cancel {
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  &.m-default {
    color: $gray-darkest;
    background-color: $gray-lightest;
    border: 1px solid $gray-light;

    &:hover {
      border: 1px solid $gray-dark;
    }
  }

  &.m-positive {
    color: $white;
    background-color: $green-dark;
    border: 1px solid $green-dark;

    &:hover {
      background-color: $green-darker;
      border-color: $green-darker;
    }
  }

  &.m-danger {
    color: $red;
    border: 1px solid $gray-light;
    background-color: $white;

    &:hover {
      color: $white;
      border: 1px solid $red;
      background-color: $red;
    }
  }

  &.m-mini {
    padding: 6px 12px;
    font-size: $font-size-mini;
  }

  &.m-tiny {
    padding: 7px 14px;
    font-size: $font-size-tiny;
  }

  &.m-small {
    padding: 8px 16px;
    font-size: $font-size-small;
  }

  &.m-normal {
    padding: 9px 18px;
    font-size: $font-size;
  }

  &.m-large {
    padding: 10px 20px;
    font-size: $font-size-large;
  }

  &.m-fluid {
    display: block;
    width: 100%;
  }

  &.m-loading {
    position: relative;
    cursor: default;
    text-shadow: none !important;
    color: transparent !important;
    opacity: 1;
    pointer-events: auto;
    -webkit-transition: all 0s linear, opacity 0.1s ease;
    transition: all 0s linear, opacity 0.1s ease;

    &:before {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -0.64285714em 0 0 -0.64285714em;
      width: 1.28571429em;
      height: 1.28571429em;
      border-radius: 500rem;
      border: 0.2em solid rgba(0, 0, 0, 0.15);
    }

    &:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -0.64285714em 0 0 -0.64285714em;
      width: 1.28571429em;
      height: 1.28571429em;
      -webkit-animation: button-spin 0.6s linear;
      animation: button-spin 0.6s linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: #fff transparent transparent;
      border-style: solid;
      border-width: 0.2em;
      -webkit-box-shadow: 0 0 0 1px transparent;
      box-shadow: 0 0 0 1px transparent;
    }
  }

  &.m-disabled {
    @include disabled();
  }

  &.m-social {
    background-color: $white;
    color: $gray-darkest;
    text-align: center;
    height: 40px;
    line-height: 22px;
    padding: 8px 0px;
    border: none;
    box-shadow: 0 0 0 1px rgba(20, 11, 47, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.2);

    &.m-loading img {
      display: none;
    }

    img {
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #fafafb;
      color: $gray-darkest;
      border: none;
      box-shadow: 0 0 0 1px rgba(20, 11, 47, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.2);
    }
  }
}

@-webkit-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
