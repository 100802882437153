@import "~@fider/assets/styles/variables.scss";

.c-vote-counter {
  margin-right: 10px;

  button,
  button.m-voted,
  button.m-disabled {
    font-size: 18px;
    border: none;
    width: 45px;
    font-weight: 700;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    margin: 0 auto;
    padding: 0 0;
    color: $gray-darker;

    .icon {
      color: $gray;
      margin-bottom: -3px;
    }
  }

  button.m-disabled {
    @include disabled();
    cursor: not-allowed;
  }

  button.m-voted,
  button.no-touch:hover {
    color: $main-color;

    .icon {
      color: $main-color;
    }
  }

  .icon {
    margin: 0 auto;
    display: block;
  }
}
