.c-segment.l-response {
  padding: 8px;

  .content {
    margin-top: 5px;
  }

  .c-avatar {
    margin-left: 3px;
  }

  span.date {
    margin-left: 3px;
  }
}
