@import "~@fider/assets/styles/variables.scss";

.c-list {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &.m-hover {
    .c-list-item {
      padding: 5px;
    }
    .c-list-item:hover {
      background-color: $gray-lighter;
      border-radius: 2px;
    }
  }

  &.m-divided {
    .c-list-item {
      padding: 15px 0 0 0;
      margin: 0 0 15px 0;
      border-top: 1px solid $gray-lighter;
      &:first-child {
        border-top: none;
        padding-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .c-list-item {
    display: list-item;
    table-layout: fixed;
    list-style-type: none;
    list-style-position: outside;
    padding: 3px 0;
    border: none;

    &.m-selectable {
      cursor: pointer;
    }

    .showover {
      visibility: hidden;
    }

    &:hover {
      .showover {
        visibility: visible;
      }
    }
  }
}
