@import "~@fider/assets/styles/variables.scss";

.c-form {
  position: relative;
  max-width: 100%;

  &.m-mini {
    font-size: 80%;
    input[type="text"],
    textarea,
    select {
      padding: 8px;
    }
  }
}

.c-form-field {
  clear: both;
  margin: 0 0 10px;

  &.m-checkbox {
    label {
      font-weight: initial;
    }
    input[type="checkbox"] {
      vertical-align: middle;
      margin: 0 5px 3px 0;
    }
  }

  label {
    display: block;
    margin: 0 0 5px;
    font-weight: 600;

    .info {
      padding: 5px 0px 0px 5px;
    }
    .info.clickable {
      color: $link-color;
    }
  }

  .c-form-field-wrapper {
    position: relative;
    margin: 0 0 5px;
  }

  input[type="text"],
  textarea,
  select {
    background-color: $white;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    padding: 10px;
    outline: 0;
    margin: 0;
    resize: none !important;
    border: 1px solid $gray-light;
    border-radius: 3px;

    &:focus {
      border: 1px solid $gray;
      box-shadow: 0px 0px 1px 1px $gray-light;
    }

    &:disabled {
      opacity: 0.45;
      cursor: not-allowed;
    }

    & + .icon {
      pointer-events: none;
      cursor: default;
      position: absolute;
      opacity: 0.5;
      width: 35px;
      height: 100%;
      padding: 12px 0px;
      margin: 0;
      top: 0;
      right: 0;
      position: absolute;

      &.link {
        cursor: pointer;
        pointer-events: inherit;
      }
    }
  }

  &.m-error {
    input[type="text"],
    textarea,
    select {
      border-color: $red;
    }

    label {
      color: $red;
    }
  }

  &.m-icon {
    input[type="text"] {
      padding-right: 35px;
    }
  }

  &.m-suffix {
    .c-form-field-wrapper {
      display: inline-flex;
      width: 100%;
    }
    .c-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    input[type="text"] {
      max-width: 100%;
      width: auto;
      flex: 1 0 auto;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .c-form-input-suffix {
      line-height: 20px;
      flex: 0 0 auto;
      padding: 8px 10px 9px 10px;
      font-weight: 600;
      background-color: $gray-lighter;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .c-form-radio-option {
    label {
      display: inline-block;
      margin-left: 5px;
      font-size: $font-size-small;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.c-form-field-error {
  color: $red;
  font-weight: bold;
  font-size: $font-size-small;
  margin: 0 0 10px 0;
  ul {
    list-style: none;
    padding-left: 5px;
    margin: 0;
  }
}

::placeholder {
  color: $gray-dark;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $gray-dark;
}

::-ms-input-placeholder {
  color: $gray-dark;
}
