@import "~@fider/assets/styles/variables.scss";

.c-message {
  padding: 15px;
  margin-bottom: 10px;

  .icon {
    margin-right: 5px;
    margin-top: -4px;
    height: 14px;
  }

  &.m-success {
    color: $green-darker;
    border-left: 4px solid $green;
    background-color: $green-lightest;
  }

  &.m-warning {
    color: $yellow-darker;
    border-left: 4px solid $yellow;
    background-color: $yellow-lightest;
  }

  &.m-error {
    color: $red-darker;
    border-left: 4px solid $red;
    background-color: $red-lightest;
  }
}
