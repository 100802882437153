@import "~@fider/assets/styles/variables.scss";

#c-footer {
  flex-shrink: 0;
  height: 80px;
  font-size: $font-size-tiny;

  .container {
    text-align: center;
    padding-top: 10px;
    border-top: 1px solid $gray-lighter;

    .l-powered {
      img {
        margin: 15px auto 0px auto;
        filter: grayscale(100%);
        display: block;
      }
      span {
        color: rgba($gray-darkest, 0.5);
        display: none;
      }
      &:hover {
        img {
          filter: grayscale(0%);
        }
        span {
          display: block;
        }
      }
    }

    .l-links a {
      color: rgba($gray-darker, 0.9);
      margin: 0px 10px;
      &:hover {
        color: $link-color;
      }
    }
  }
}
